<template>
<div class="footer dirr">
    <div class="footerCon">
        <v-layout row>
            <v-flex xs12 sm6 md3 lg3 style="padding: 10px;">
                <div class="first">
                    <p class="tl ta" >{{ $t("center") }}</p>
                    <p class="tx ta" >{{ $t("desc") }}</p>
                    <div class="social">
                        <a href="">
                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.40942 0H15.1438C18.7029 0 21.5891 3.07861 21.5891 6.875V15.125C21.5891 18.9214 18.7029 22 15.1438 22H7.40942C3.85032 22 0.964111 18.9214 0.964111 15.125V6.875C0.964111 3.07861 3.85032 0 7.40942 0ZM15.1438 19.9375C17.6317 19.9375 19.6555 17.7787 19.6555 15.125V6.875C19.6555 4.22125 17.6317 2.0625 15.1438 2.0625H7.40942C4.92153 2.0625 2.89771 4.22125 2.89771 6.875V15.125C2.89771 17.7787 4.92153 19.9375 7.40942 19.9375H15.1438Z" fill="#312F2F"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M6.12036 11C6.12036 7.96262 8.42907 5.5 11.2766 5.5C14.1242 5.5 16.4329 7.96262 16.4329 11C16.4329 14.0374 14.1242 16.5 11.2766 16.5C8.42907 16.5 6.12036 14.0374 6.12036 11ZM8.05396 11C8.05396 12.8947 9.50028 14.4375 11.2766 14.4375C13.0529 14.4375 14.4993 12.8947 14.4993 11C14.4993 9.10388 13.0529 7.5625 11.2766 7.5625C9.50028 7.5625 8.05396 9.10388 8.05396 11Z" fill="#312F2F"/>
                                <ellipse cx="16.8195" cy="5.08761" rx="0.68707" ry="0.732875" fill="#312F2F"/>
                            </svg>
                        </a>
                        <a href="">
                            <svg width="21" height="18" viewBox="0 0 21 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M20.625 2.11613C19.858 2.475 19.0407 2.71288 18.1887 2.82838C19.0652 2.27013 19.7343 1.39287 20.0488 0.3355C19.2315 0.85525 18.3292 1.22238 17.3675 1.42725C16.5915 0.545875 15.4855 0 14.2789 0C11.938 0 10.0534 2.02675 10.0534 4.51137C10.0534 4.86888 10.0818 5.21263 10.1514 5.53988C6.63609 5.357 3.52559 3.55988 1.43602 0.82225C1.07121 1.49738 0.857227 2.27012 0.857227 3.102C0.857227 4.664 1.61133 6.04862 2.73539 6.85025C2.05605 6.8365 1.38961 6.62613 0.825 6.29475C0.825 6.3085 0.825 6.32638 0.825 6.34425C0.825 8.536 2.29066 10.3565 4.21266 10.7759C3.86848 10.8763 3.49336 10.9244 3.10406 10.9244C2.83336 10.9244 2.56008 10.9079 2.30355 10.8474C2.85141 12.6335 4.40602 13.9466 6.25453 13.9893C4.81594 15.1896 2.98934 15.9129 1.01191 15.9129C0.665156 15.9129 0.332578 15.8964 0 15.851C1.87301 17.1394 4.09277 17.875 6.48656 17.875C14.2673 17.875 18.5212 11 18.5212 5.04075C18.5212 4.84137 18.5148 4.64887 18.5058 4.45775C19.345 3.8225 20.0501 3.02913 20.625 2.11613Z" fill="#312F2F"/>
                            </svg>
                        </a>
                        <a href="">
                            <svg width="10" height="20" viewBox="0 0 10 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7.60465 3.29509H9.3031V0.139742C9.01008 0.0967442 8.00233 0 6.82868 0C4.37984 0 2.70233 1.643 2.70233 4.66274V7.44186H0V10.9693H2.70233V19.845H6.0155V10.9701H8.60853L9.02015 7.44269H6.01473V5.01251C6.0155 3.99297 6.27287 3.29509 7.60465 3.29509Z" fill="#312F2F"/>
                            </svg>
                        </a>
                    </div>
                </div>
            </v-flex>
            <v-flex xs12 sm6 md2 lg2 style="padding: 10px;">
                <div class="second">
                    <p class="tl ta Title">{{ $t("divisions") }}</p>
                    <v-layout row>
                        <v-flex xs6 sm6 md12 lg12>
                            <router-link :to="'/news/?&lang=' +$i18n.locale">
                                <p class="tl ta Text">{{ $t("about") }}</p>
                            </router-link>
                        </v-flex>
                      
                        <v-flex xs6 sm6 md12 lg12>
                            <router-link :to="'/news/?&lang=' +$i18n.locale">
                                <p class="tl ta Text">{{ $t("blog") }}</p>
                            </router-link>
                        </v-flex>
                        <v-flex xs6 sm6 md12 lg12>
                            <router-link :to="'/news/?&lang=' +$i18n.locale">
                                <p class="tl ta Text">{{ $t("library") }}</p>
                            </router-link>
                        </v-flex>
                    </v-layout>
                </div>
            </v-flex>
            <v-flex xs12 sm6 md2 lg2 style="padding: 10px;">
                <div class="third">
                    <p class="tl ta Title">{{ $t("friendlySites") }}</p>
                    <router-link :to="'/news/?&lang=' +$i18n.locale">
                        <p class="tl ta Text">{{ $t("test") }}</p>
                    </router-link>
                    <router-link :to="'/news/?&lang=' +$i18n.locale">
                        <p class="tl ta Text">{{ $t("test") }}</p>
                    </router-link>
                    <router-link :to="'/news/?&lang=' +$i18n.locale">
                        <p class="tl ta Text">{{ $t("test") }}</p>
                    </router-link>
                    <router-link :to="'/news/?&lang=' +$i18n.locale">
                        <p class="tl ta Text">{{ $t("test") }}</p>
                    </router-link>
                </div>
            </v-flex>
            <v-flex xs12 sm6 md5 lg5 style="padding: 10px;">
                <div class="fourth">
                    <p class="tl ta Title">{{ $t("Drawer.callUs") }}</p>
                     <v-form 
    ref="form"
    v-model="valid"
    lazy-validation
  >
    <v-text-field
      v-model="name"
      :rules="nameRules"
      :label="$t('datatable.name')"
      required
    ></v-text-field>

    <v-text-field
      v-model="email"
      :rules="emailRules"
      :label="$t('datatable.email')"
      required
    ></v-text-field>

     <v-text-field
      v-model="message"
      :rules="messageRules"
      :label="$t('datatable.message')"
      required
    ></v-text-field>

    <v-btn 
      color="#145C5E" 
      @click="sendMessage"
    >
    <h2 style="color:white ;padding: 20px;">{{$t('send')}}</h2>
    </v-btn>
  </v-form>
                </div>
            </v-flex>
        </v-layout>
    </div>
    <div class="copywrite"><p class="tl" >{{ $t("copywrite") }}</p></div>
</div> 
</template>


<script>
import axios from "axios";
import Swal from "sweetalert2";
export default {
    data() {
        return {
            i:{email:''},
            loader: null,
            loading: false,
            valid: false,
            rules: {
                required: value => !!value || this.$t("required_field"),
                email: v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || this.$t("reqemail"),
            },
        valid: false,
     name: '',
      nameRules: [
        v => !!v || this.$t("reqname")
            ],
      email: '',
      emailRules: [
        v => !!v ||  this.$t("reqemail"),
        v => /.+@.+\..+/.test(v) || this.$t("cheackEmail"),
      ],
      message:'',
      messageRules:[
          v => !!v || this.$t("valMessage")
      ],
            loader: null,
            loading: false,
            impoNews_skeleton: true,
        
        }

    },
    watch: {
      loader () {
        const l = this.loader
        this[l] = !this[l]
        setTimeout(() => (this[l] = false), 3000)
        this.loader = null
      },
    },
    components: {
    },
    directives: {
    },
    computed: {
    },
    mounted() {
    }, 
    created () {
    },
    methods:{
 
    
    validate () {
        valid: true;
      },
        sendMessage(){
         
        if(this.$refs.form.validate()){
          
                  axios.post('/callus',
                 {
                name:this.name,
                email:this.email,
                message:this.message
                 }).then(()=>{
                 this.name="";
                 this.email="";
                 this.message="";
                  this.$refs.form.resetValidation();
                Swal.fire({
                                title:this.$t("sendMessage"),
                                text: "",
                                icon: "success",
                                timer: 2000,
                                confirmButtonText: this.$t('close'),
                              });
                 }).catch ((error)=>{
                console.log(error);
                 Swal.fire({
                                title: this.$t('connecterror'),
                                text: "",
                                icon: "warning",
                                timer: 2000,
                                confirmButtonText: this.$t('close'),
                              })}); 
        }}
    }
}
</script>
